import { createStore } from "vuex";
import router from "../router";
import "../firebase";
let startsWith = require("lodash/startsWith");
let findKey = require("lodash/findKey");

// import Assignments from "./Assignments";
// import Learning from "./Learning";
// import Recordings from "./Recordings";
// import Products from "./Products";
// import Services from "./Services";
import Users from "./Users";

import {
  getAuth,
  signInWithPopup,
  onAuthStateChanged,
  signOut,
  GoogleAuthProvider,
} from "firebase/auth";
import API from "../components/API";

const auth = getAuth();

const Store = {
  state: () => ({
    window: {
      width: 1366,
      timeout: null,
    },
    groups: {
      admin: ["wesley@burkedesigns.biz"],
    },
    user: {
      displayName: "John Doe",
      email: "test@email.com",
      permissions: [],
    },
    layout: {
      header: {
        accountDropdown: false,
      },
      nav: {
        active: "Submissions",
        collapse: true,
        menu: {
          // Booking: {
          //   label: "Booking",
          //   icon: "bookmark",
          //   path: "/dashboard/booking",
          // },
          // Offerings: {
          //   label: "Offerings",
          //   icon: "widgets",
          //   path: "/dashboard/offerings",
          // },
          // Submissions: {
          //   label: "Submissions",
          //   icon: "list_alt",
          //   path: "/dashboard/submissions",
          // },
          Cards: {
            key: "Cards",
            label: "Credit Cards",
            icon: "credit_card",
            path: "/dashboard/my-cards",
          },
          Billing: {
            key: "Billing",
            label: "Billing",
            icon: "payments",
            path: "/dashboard/billing",
          },
          Users: {
            key: "Users",
            label: "Users",
            icon: "people",
            path: "/dashboard/users",
            permissions: ["admin"],
          },
          // Team: {
          //   label: "Talent",
          //   icon: "work",
          //   path: "/dashboard/team",
          // },
          // Files: {
          //   label: "Files",
          //   icon: "article",
          //   path: "/dashboard/files",
          // },
        },
      },
      section: {
        loading: false,
        active: "modules",
        collapse: true,
      },
    },
  }),
  mutations: {
    resizeWindowEvent(state) {
      if (state.window.timeout != null) {
        clearTimeout(state.window.timeout);
      }
      state.window.timeout = setTimeout(function () {
        state.window.width = window.innerWidth;
      }, 100);
    },
    toggleAccountDropdown(state) {
      state.layout.header.accountDropdown =
        !state.layout.header.accountDropdown;
    },
    toggleNavState(state) {
      state.layout.nav.collapse = !state.layout.nav.collapse;
      if (state.window.width < 600) {
        state.layout.section.collapse = true;
      }
    },
    collapseSection(state, val) {
      state.layout.section.collapse = val;
    },
    googleSignIn(state) {
      const auth = getAuth();
      const provider = new GoogleAuthProvider();
      signInWithPopup(auth, provider)
        .then(async (result) => {
          // This gives you a Google Access Token. You can use it to access the Google API.
          // const credential = GoogleAuthProvider.credentialFromResult(result);
          // const token = credential.accessToken;
          // The signed-in user info.
          const user = result.user;
          console.log(user);

          state.user.displayName = auth.currentUser.displayName;
          state.user.email = auth.currentUser.email;
          state.user.photoURL = auth.currentUser.photoURL;
          router.push("/dashboard/my-cards");

          const userData = await API.post("/auth/user/data");
          if (userData.success == true && userData.user == null) {
            API.post("/auth/user/create", { ...state.user, permissions: [] });
          }
          if (userData.success == true && userData.user != null) {
            console.log("Server user data:", userData);
            state.user = userData.user;
          }
          // ...
        })
        .catch((error) => {
          // Handle Errors here.
          // const errorCode = error.code;
          const errorMessage = error.message;
          // The email of the user's account used.
          const email = error.email;
          console.log(email, errorMessage);
          // The AuthCredential type that was used.
          // const credential = GoogleAuthProvider.credentialFromError(error);
          // ...
        });
    },
    signOut() {
      signOut(auth)
        .then(() => {
          // Sign-out successful.
        })
        .catch(() => {
          // An error happened.
        });
    },
    async loadUser(state) {
      const userData = await API.post("/auth/user/data");
      if (userData.success == true && userData.user != null) {
        state.user = userData.user;
      }
    },
    navTo(state, section) {
      if (state.window.width < 600) {
        state.layout.nav.collapse = !state.layout.nav.collapse;
      }
      state.layout.nav.active = section;
      router.push(state.layout.nav.menu[section].path);
      // this.layout.section.collapse = true;
    },
    activateNavPath(state) {
      let path = router.currentRoute.value.path;
      const key = findKey(state.layout.nav.menu, function (item) {
        return startsWith(path, item.path);
      });
      if (key != null) {
        state.layout.nav.active = key;
      }
    },
  },
  actions: {},
  // computed
  getters: {
    isAdmin: (state) => {
      return (
        auth.currentUser != null && state.user.permissions.includes("admin")
      );
    },
  },
};

const vuex = createStore({
  modules: {
    // products: Products,
    // services: Services,
    users: Users,
    main: Store,
  },
});

export default vuex;
