import { auth } from "@/firebase";
import dialog from "@/components/dialog.js";

// -----------------------------------
// Public interface
// -----------------------------------

const API = {
  async post(url, body) {
    try {
      const token = await auth.currentUser.getIdToken();
      const data = await fetch(
        `https://mycardian-api.burkedesigns.workers.dev${url}`,
        {
          headers: new Headers({
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `${token}`,
          }),
          method: "POST",
          body: JSON.stringify(body),
        }
      );
      const res = await data.json();
      if (res.success == false) {
        await dialog.title(res.msg).alert(res.error);
      }
      return res;
    } catch (error) {
      await dialog
        .title("Unknown Error")
        .alert(
          `Please check your internet connection and try again. Error details: ${error}`
        );
      console.error(`Error: ${error}`);
    }
    // return open(res);
  },
  async getToken() {
    const token = await auth.currentUser.getIdToken();
    return token;
  },
  async get(message) {
    return open(message);
  },
};

export default API;
