// import router from "@/router";
// import { reactive } from "vue";
import { db } from "@/firebase";
import {
  collection,
  query,
  // where,
  onSnapshot,
  orderBy,
} from "firebase/firestore";

// import dialog from '@/components/dialog.js'
import API from "@/components/API";

const Data = {
  namespaced: true,
  state: () => ({
    list: {},
    unsubscribe: null,
    status: "loading",
  }),
  //   Methods
  mutations: {
    async loadList(state) {
      state.list = {};
      const q = query(
        collection(db, "users"),
        orderBy("createdOn", "desc")
        // where("status", "==", "publish")
      );
      if (state.unsubscribe != null) {
        state.unsubscribe();
      }
      state.unsubscribe = onSnapshot(q, (querySnapshot) => {
        querySnapshot.forEach((doc) => {
          let data = doc.data();
          data.id = doc.id;
          state.list[doc.id] = data;
        });
        state.status = "loaded";
      });
      // console.log(state.list);
    },
    async add(state, data) {
      let result = await API.post("/auth/user/create", data);
      if (result.success == true) {
        state.list[result.service.id] = result.service;
        console.log("Added Service", result);
      }
    },
    async update(state, data) {
      let result = await API.post("/auth/user/update", data);
      if (result.success == true) {
        state.list[result.service.id] = result.service;
        console.log("Updated Service", result);
      }
    },
    delete(state, id) {
      delete state.list[id];
    },
  },
  actions: {},
  //   Computed Values
  getters: {},
};

export default Data;
