<template>
  <Teleport to="body">
    <div :el="mods" v-if="active">
    <List el="md rnd3">
      <List el="column-between" style="align-items:center;">
        <Text el="h2" v-if="title">{{ title }}</Text>
        <Icon name="close" style="font-size: 40px;" @click="toggle" />
      </List>
      <slot></slot>
    </List>
  </div>
  </Teleport>
</template>

<script>
import { check } from "./verify";
import List from "./List.vue";
import Text from "./Text.vue";
import Icon from "@/components/btn/Icon.vue";
const name = "modal";
export default {
  components: {
    List,
    Text,
    Icon
  },
  props: {
    el: {
      type: String,
      default: "center",
    },
    title: {
      type: String,
    },
  },
  data() {
    return {
      active: false,
    };
  },
  methods: {
    toggle() {
      this.active = !this.active;
    },
  },
  watch: {
    active(val) {
      if (val) {
        document.querySelector("body").classList.add("stop-scrolling");
      } else {
        document.querySelector("body").classList.remove("stop-scrolling");
      }
    },
  },
  computed: {
    mods() {
      return this.el ? `${name}-${this.el}` : name;
    },
  },
  mounted() {
    check(name, this.el);
  },
};
</script>