import { createRouter, createWebHistory } from "vue-router";
import Login from "../views/Login.vue";
import { getAuth } from "firebase/auth";
// import { auth } from "../firebase";
// import { useStore } from "vuex";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Login,
  },
  // {
  //   path: "/docs",
  //   name: "Docs",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import("../views/Docs.vue"),
  //   meta: {
  //     requiresAuth: true,
  //   },
  // },
  // {
  //   path: "/login",
  //   name: "Login",
  //   component: function() {
  //     return import("../views/Login.vue");
  //   },
  // },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: () => import("../views/Dashboard.vue"),
    meta: {
      requiresAuth: true,
    },
    children: [
      // {
      //   path: "booking",
      //   components: {
      //     default: () => import("../views/Booking.vue"),
      //     // section: () => import("../views/ViewBooking.vue"),
      //   },
      // },
      // {
      //   path: "files",
      //   components: {
      //     default: () => import("../views/Files.vue"),
      //   },
      // },
      {
        path: "my-cards",
        components: {
          default: () => import("../views/MyCards.vue"),
        },
      },
      {
        path: "billing",
        components: {
          default: () => import("../views/Billing.vue"),
        },
      },
      {
        path: "settings",
        components: {
          default: () => import("../views/Settings.vue"),
        },
      },
      // {
      //   path: "submissions",
      //   components: {
      //     default: () => import("../views/Submissions.vue"),
      //   },
      // },
      {
        path: "users",
        components: {
          default: () => import("../views/Users.vue"),
        },
        meta: {
          permissions: ["admin"],
        },
      },
      // {
      //   path: "offerings",
      //   components: {
      //     default: () => import("../views/Offerings.vue"),
      //   },
      // },
      // {
      //   path: "team",
      //   components: {
      //     default: () => import("../views/Team.vue"),
      //   },
      // },
      {
        path: "profile",
        components: {
          default: () => import("../views/Profile.vue"),
        },
      },
    ],
  },
  {
    path: "/file/:collection/:path/:filename",
    name: "File",
    component: () => import("../views/ViewFile.vue"),
    meta: {
      requiresAuth: true,
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  // const store = useStore();
  const auth = getAuth();
  const requiresAuth = to.matched.some((x) => x.meta.requiresAuth);
  // const requiresAdmin = to.matched.some(
  //   (x) => x.meta.permissions && x.meta.permissions.includes("admin")
  // );
  // console.log("Is admin", store.state.main.user);
  // console.log("Is admin getter", store.getters.isAdmin);
  // console.log("requires admin", requiresAdmin);
  if (requiresAuth && !auth.currentUser) return next("/");
  // if (requiresAdmin && !store.getters.isAdmin) return next("/");
  next();
});

export default router;
