import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "./firebase";
import DesignSystem from "../design-system/vue.js";
import API from "./components/API";

let app;
onAuthStateChanged(auth, async (user) => {
  if (!app) {
    app = createApp(App);
    // Design System component registration
    DesignSystem.register(app);

    app.use(store).use(router).mount("#app");
  }

  if (user) {
    // User is signed in, see docs for a list of available properties
    // https://firebase.google.com/docs/reference/js/firebase.User
    // const uid = user.uid;
    // router.push("/dashboard");
    store.state.main.user.displayName = user.displayName;
    store.state.main.user.email = user.email;
    store.state.main.user.photoURL = user.photoURL;
    await loadUser();
    router.push("/dashboard/my-cards");
    // console.log(user);
  } else {
    // User is signed out
    router.push("/");
  }
});

async function loadUser() {
  console.log("loading user...");
  const userData = await API.post("/auth/user/data");
  if (userData.success == true && userData.user != null) {
    store.state.main.user = userData.user;
    console.log("load data user", userData);
  }
}
