<template>
  <router-view />
  <Dialog />
</template>
<script>
import Dialog from '@/components/Dialog.vue';

export default {
  components:{
    Dialog
  },
  mounted() {
    var x = this;
    this.$store.commit("resizeWindowEvent");
    window.addEventListener(
      "resize",
      function() {
        x.$store.commit("resizeWindowEvent");
      },
      { passive: true }
    );
  },
};
</script>
<style lang="scss">
// @import "design-system/assets/scss/core";
@import "design-system/assets/scss/standard";
</style>
